import { Padding } from '@mui/icons-material'
import theme from './theme'

const profiletheme = {
  heading: {
    mainHeading: {
      marginBottom: 4,
      fontSize: 48,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      color: theme.palette.primary.main,
      textAlign: 'left',
    },
    titleHeading1: {
      marginBottom: 1,
      fontSize: 36,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
    titleHeading2: {
      fontFamily: 'Roboto',
      fontSize: 24,
      fontWeight: 'bold',
      color: '#002147',
    },
  },
  text: {
    textLight: {
      marginBottom: 1,
      fontSize: 18,
      fontWeight: 'light',
      fontFamily: 'Roboto',
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
    textRegular: {
      fontFamily: 'Roboto',
      fontSize: 20,
      fontWeight: 'regular',
      color: '#767676',
    },
    textRegularSide: {
      fontSize: 24,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      color: '#002147',
      textAlign: 'center',
    },
  },
  container: {
    sectionContainer: {
      maxWidth: '1280px',
      margin: '0 auto',
      padding: '20px'
    },
    rootContainer: {
      width: '100%',
      my: 10,
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 3,
    },
    cardContainer: {
      width: { md: '50%', sm: '100%', lg: '70%' },
      height: 'auto',
      backgroundColor: '#afc3d9',
      borderRadius: 8,
    },
    cardSideContainer: {
      width: { md: '50%', sm: '100%', lg: '30%' },
      backgroundColor: '#e2e1e1',
      borderRadius: 8,
    },
  },
  box: {
    boxStyle: {
      py: 3,
      px: 4,
      backgroundColor: '#95a8bd',
    },
    boxBodyStyle: {
      width: '100%',
      p: 4,
      display: 'flex',
      flexDirection: 'column',
    },
    boxBodyStyle2: {
      marginTop: 2,
      display: 'flex',
      flexDirection: 'row',
      gap: 2,
    },
    boxContentStyle: {
      width: '100%',
      marginBottom: 4,
    },
    boxSideContentstyle: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 1,
      backgroundColor: '#d2d2d2',
      px: 4,
      py: 2,
    },
  },
  form: {
    formStyle: {
      marginBottom: 2,
    },
    formLabel: {
      fontFamily: 'Roboto',
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 1,
      color: '#002147',
    },
    formInput: {
      backgroundColor: '#ffffff',
      borderRadius: 4,
      border: '1px solid #656565',
      boxShadow: 'inset -1px -1px 4px #656565',
      '& .MuiOutlinedInput-root': {
        '& .MuiInputBase-input': {
          height: 40,
          fontSize: 20,
        },
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
  },
  button: {
    buttonIcon: {
      height: '16px',
      width: '16px'
    },
    buttonMain: {
      py: 2,
      px: 4,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 2,
      borderRadius: 0,
      fontSize: 16,
      fontWeight: 'medium',
      color: '#002147',
      textAlign: 'left',
    },
    buttonActive: {
      backgroundColor: '#f36b3b',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#d75e33',
      },
    },
    backButton: {
      alignSelf: 'flex-start',
      color: 'secondary.main',
      fontSize: '1.2rem',
      fontWeight: 700,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }
  },
  navigation: {
    mainNav: {
      position: 'relative',
      padding: 0,
      boxShadow: 'none',
      fontFamily: 'Outfit, sans-serif',
    },
    mainNavContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: { xs: '0 10px', sm: '10px 20px' },
      margin: '0 auto',
      maxWidth: '1280px',
      width: '100%',
      minHeight: 'auto !important',
    },
    mainNavList: {
      display: { md: 'flex', xs: 'none' },
      flexDirection: 'row',
    },
    mainNavLists: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: { xs: 'column', md: 'row' },
      padding: 0,
      gap: '18px',
    },
    mainNavListItem: {
      padding: '12px',
      width: 'auto',
      whiteSpace: 'nowrap',
    },
    mainNavListItemLink: {
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Outfit, sans-serif',
      color: '#ffffff',
      textDecoration: 'none',
      textTransform: 'Capitalize',
      transition: 'all 0.4s ease',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.8)',
      },
    },
    mainNavLogo: {
      height: '48px',
      width: '211px',
      maxWidth: '100%',
    },
    mainNavLogoContainer: {
      padding: '40px 0 40px',
      margin: '0 0 10px',
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    },
    mainNavDrawerContainer: {
      textAlign: 'center',
      color: 'common.white',
    },
    mainNavDrawer: {
      display: { xs: 'block', md: 'none' },
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        backgroundColor: 'primary.main',
        padding: '0 20px',
      },
    },
    mainNavSearchButton: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      padding: '10px 20px',
      width: '100%',
      height: '100%',
      minHeight: '48px',
      whiteSpace: 'nowrap',
      textTransform: 'capitalize',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: '#F04D12',
      },
    },
    mainNavLoggedInButton: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      padding: '10px 20px',
      width: '100%',
      height: '100%',
      minHeight: '48px',
      whiteSpace: 'nowrap',
      textTransform: 'capitalize',
      fontSize: '16px',
      backgroundColor: 'transparent',
      border: '1px solid rgba(255, 255, 255, 0.5)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
    },
    mainNavLogoutButton: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      padding: '10px 20px',
      width: '100%',
      height: '100%',
      minHeight: '48px',
      whiteSpace: 'nowrap',
      textTransform: 'capitalize',
      fontSize: '16px',
      backgroundColor: '#FE4949',
      '&:hover': {
        backgroundColor: '#E80101',
      },
    },
    mainNavLoginButton: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      padding: '10px 15px',
      width: '100%',
      height: '100%',
      minHeight: '48px',
      whiteSpace: 'nowrap',
      textTransform: 'capitalize',
      fontSize: '16px',
      fontFamily: 'Outfit, sans-serif',
      backgroundColor: 'transparent',
      border: '1px solid rgba(255, 255, 255, 0.5)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
    },
    mainNavListSignUp: {
      display: 'flex',
      gap: '10px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      padding: '10px 20px',
      width: '100%',
      height: '100%',
      minHeight: '48px',
      whiteSpace: 'nowrap',
      textTransform: 'capitalize',
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Outfit, sans-serif',
      color: '#ffffff',
      textDecoration: 'none',
      transition: 'all 0.4s ease',
      border: '1px solid rgba(255, 255, 255, 0.5)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
    },
    mainNavListSignUpSpan: {
      position: 'absolute',
      backgroundColor: '#FE4949',
      fontSize: '11px',
      color: '#FFFFFF',
      padding: '2px 4px',
      borderRadius: '2px',
      left: { xs: '83px', md: '25px' },
      top: '-10px',
    },
    mainNavDivider: {
      height: { xs: '1px', md: '36px' },
      width: { xs: '100%', md: '1px' },
      margin: { xs: '10px 0', md: '0' },
      border: '1px solid #E0EFFF',
      borderRadius: '5px',
      opacity: { xs: '0.1', md: '0.3' },
      fontFamily: 'Outfit, sans-serif',
    },
    topNav: {
      background: '#000000',
      width: '100%',
    },
    topNavContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 'auto',
      maxWidth: '1280px',
      margin: '0 auto',
      width: '100%',
      padding: '5px 20px',
    },
    topNavLeft: {
      height: 'auto',
    },
    topNavRight: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      height: 'auto',
    },
    topNavIcon: {
      height: '24px',
    },
    topNavLink: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      fontSize: '14px',
      fontWeight: '400',
      color: '#FFFFFF',
      fontFamily: 'Outfit, sans-serif',
      textDecoration: 'none',
      transition: 'all 0.6 ease',
      '&:hover': {
        opacity: '0.8',
      },
    },
  },
  section: {
    sectionMain: {
      Width: '100%',
      backgroundColor: '#002753',
      fontFamily: 'Outfit, sans-serif',
    },
    sectionContainer: {
      height: 'auto',
      maxWidth: '1280px',
      margin: '0 auto',
      width: '100%',
      padding: '20px 20px',
    },
    sectionHeading: {
      fontSize: '18px',
      fontWeight: '700',
      color: '#FFFFFF',
      textAlign: 'center',
      marginBottom: '10px',
      fontFamily: 'Outfit, sans-serif',
    },
    sectionDescriptionSmall: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#FFFFFF',
      textAlign: 'center',
      margin: '0',
      fontFamily: 'Outfit, sans-serif',
    },
  },
  footer: {
    backgroundColor: '#002147',
    footerContainer: {
      height: 'auto',
      maxWidth: '1280px',
      margin: '0 auto',
      width: '100%',
      display: 'flex',
      flexDirection: { xs: 'column', lg: 'row' },
      justifyContent: { md: 'center', lg: 'space-between' },
      alignItems: 'center',
      padding: '10px 20px',
    },
    footerLeft: {
      display: 'flex',
      gap: { xs: '10px', lg: '20px' },
      marginBottom: { xs: '20px', lg: '0' },
      flexDirection: { xs: 'column', lg: 'row' },
      justifyContent: 'center',
      alignItems: 'center',
    },
    footerLink: {
      marginTop: '2px',
      height: '18px',
    },
    footerCopyright: {
      fontSize: { xs: '14px', lg: '14px' },
      fontWeight: '400',
      color: '#FFFFFF',
      fontFamily: 'Outfit, sans-serif',
    },
    footerList: {
      display: 'flex',
      flexDirection: { sx: 'row' },
      alignItems: 'center',
      gap: '15px',
      padding: 0,
    },
    footerListItem: {
      padding: 0,
      whiteSpace: 'nowrap',
    },
    footerListItemLink: {
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Outfit, sans-serif',
      color: '#FFFFFF',
      textDecoration: 'none',
    },
  },    
  bookmarks: {
    bookmarksSection: {
      padding: "60px 0"
    },
    bookmarksBox: {
      margin: "40px 0 0"
    },
    bookmarksTabs: {
      borderBottom: "1px solid #E4E9EF",
      marginBottom: "30px",
      '.MuiTabs-indicator': {
        display: 'none',
      }
    },
    bookmarksTab: {
      padding: { xs: '10px 15px', lg: "20px 26px" },
      border: "1px solid #E4E9EF",
      color: "rgba(97, 97, 97, .87)",
      fontSize: { xs: '10px', lg: '14px' },
      '&.Mui-selected': {
        background: "#E6F1FF",
        color: "rgba(0, 0, 0, .87)"
      }
    },
    bookmarksGridTable: {
      '.MuiDataGrid-root': {
        border: 'none',
      },
      '.MuiDataGrid-main': {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#E0EFFF',
      },
      '.MuiDataGrid-footerContainer': {
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      },
      '& .MuiDataGrid-footerContainer': {
        backgroundColor: '#E0EFFF', // Change table header color
      },
      '& .MuiDataGrid-row': {
        backgroundColor: '#FFFFFF',
      },
      '& .inactive.MuiDataGrid-row': {
        backgroundColor: '#DFDFDF',
        color: "#828282"
      },
      '& .MuiDataGrid-overlay': {
        zIndex: '20',
      },
      borderRadius: '8px',
      fontFamily: 'Outfit, sans-serif',
      fontSize: {
        xs: '12px',
        md: '1rem',
      },
      fontWeight: 400,
      '& .MuiDataGrid-row:hover': {
        backgroundColor: 'secondary.main',
      },
      "& .MuiDataGrid-cell--withRenderer": {
        maxWidth: { xs: '85px !important' }
      }
    },
    bookmarksButtonActive: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
      width: 'auto',
      fontFamily: "Outfit, sans-serif",
      fontSize: { xs: '10px', lg: '14px' },
      fontWeight: "500",
      color: "#FFFFFF",
      backgroundColor: "#002147",
      borderRadius: "4px",
      padding: { xs: '3px', lg: '6px' },
      "&:hover": {
        backgroundColor: "#2B71C1",
      },
      "svg": {
        width: { xs: '14px', lg: '16px' }
      },
    }
  }
}

export default profiletheme
