import React from 'react'
import { Link } from 'react-router-dom'
import './WelcomeButton.css'

export interface PrimaryButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string
  icon?: string
  desc?: string
  url?: string
}

const WelcomeButton: React.FC<PrimaryButtonProps> = ({
  label,
  icon,
  desc,
  url,
}: PrimaryButtonProps) => {
  return (
    <Link to={`${url}`} className="button__welcome" id='img-btn-welcome'>
      <button className="button__welcome-primary">
        {icon && <img src={`${icon}`} alt="" />}
        <p>{label}</p>
        {desc && <span>{desc}</span>}
      </button>
    </Link>
  )
}

export default WelcomeButton
